import React from 'react'
import { TimeSpan } from './TimeSpan'
import { Location } from './Location'
import styles from './TimeAndLocation.module.css'

const iconProps = {
  inheritColor: true,
  shifted: true
}
export const TimeAndLocation = ({ startDate, endDate, location }) => (
  <small className={styles.container}>
      <span className={styles.date}>
        <TimeSpan startDate={startDate} endDate={endDate} iconProps={iconProps} />
      </span>
    {!!location &&
    <span>
        <Location location={location} iconProps={iconProps} />
      </span>
    }
  </small>
)
import React from 'react'
import styles from './Utility.module.css'
import classnames from 'classnames'

export const Utility = ({ fontSize, children }) => {
  const className = classnames({
    [styles.bigFontSize]: fontSize === 'big',
    [styles.biggerFontSize]: fontSize === 'bigger',
  })
  return <div className={className}>{children}</div>
}
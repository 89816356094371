import React from 'react'
import styles from './Projects.module.css'
import { Project } from './Project'

export const Projects = ({ projects }) => <div className={styles.container}>
  {projects.map(project => (
    <Project
      key={project.name}
      name={project.name}
      description={project.description}
      website={project.website}
    />
  ))}
</div>
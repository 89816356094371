import React from 'react'
import { TimeAndLocation } from './TimeAndLocation'
import styles from './DetailsSection.module.css'
import { Heading } from './Heading'
import { Description } from './Description'
import { Projects } from './Projects'
import { Badge } from './Badge'

export const DetailsSection = ({ title, organisation, location, startDate, endDate, overview, projects, technologies }) => (
  <div className={styles.container}>
    <Heading>{title}</Heading>
    {!!organisation && <span className={styles.organisation}>{organisation}</span>}
    <TimeAndLocation startDate={startDate} endDate={endDate} location={location} />
    <Description>{overview}</Description>
    {projects && <Projects projects={projects} />}
    {technologies && technologies.map(tech => <Badge key={tech} text={tech} />)}
  </div>
)

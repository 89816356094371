import 'bootstrap/dist/css/bootstrap.css'
import React from 'react'
import {
  education,
  experience,
  languages,
  passions,
  personalProjects,
  skills,
  teachings,
  userDescription,
  userEmail,
  userJobTitle,
  userLinks,
  userLocation,
  userLocationUrl,
  userName,
  userPhone,
  volunteering
} from '../../site-config'
import { Badge } from '../components/Badge'
import { ContactEntry } from '../components/ContactEntry'
import { DetailsSection } from '../components/DetailsSection'
import { HeaderSpacer } from '../components/HeaderSpacer'
import { Headline } from '../components/Headline'
import { Language } from '../components/Language'
import { Page } from '../components/layout/Page'
import { Passion } from '../components/Passion'
import { Section } from '../components/Section'
import { SectionBody } from '../components/SectionBody'
import { Utility } from '../components/Utility'
import userAvatar from '../static/images/cropped.jpg'
import { getColumnClasses } from '../utils/styling'

const [mainColumnClass, additionalColumnClass] = getColumnClasses(8)

export default (props) =>
  <Page {...props}>
    <div className="row">
      <div className='col-sm col-sm-8'>
        <HeaderSpacer>
          <Section>
            <h1 className="header-user-name">{userName}</h1>
            <h4 className="header-job-title">{userJobTitle}</h4>
            <ul className="list-inline">
              <li className="list-inline-item">
                <ContactEntry url={'tel:' + userPhone} iconClassName='fas fa-phone' text={userPhone} />
              </li>
              <li className="list-inline-item contact-entry">
                <ContactEntry url={'mailto:' + userEmail} iconClassName='fas fa-at' text={userEmail} />
              </li>
              <li className="list-inline-item contact-entry">
                <ContactEntry url={userLocationUrl} iconClassName='fas fa-home' text={userLocation} />
              </li>
            </ul>
          </Section>
        </HeaderSpacer>
        <Section>
          <Headline>Experience</Headline>
          <SectionBody>
            {
              experience.map((detail) =>
                <DetailsSection
                  key={detail.position + detail.company}
                  title={detail.position}
                  startDate={detail.startDate}
                  endDate={detail.endDate}
                  current={detail.current}
                  overview={detail.overview}
                  location={detail.location}
                  organisation={detail.company}
                  projects={detail.projects}
                  technologies={detail.technologies}
                />
              )
            }
          </SectionBody>
        </Section>
        <Section>
          <Headline>Education</Headline>
          <SectionBody>
            {
              education.map((detail) =>
                <DetailsSection
                  key={detail.college}
                  title={detail.degree}
                  organisation={detail.college}
                  startDate={detail.startDate}
                  endDate={detail.endDate}
                  location={detail.location}
                  overview={detail.overview}
                />
              )
            }
          </SectionBody>
        </Section>
      </div>
      <div className='col-sm col-sm-4'>
        <HeaderSpacer className="header-spacer">
          <div className='user-image-wrapper'>
            <img className="user-image rounded-circle" src={userAvatar} alt={userName} />
          </div>
        </HeaderSpacer>
        <Section>
          <Headline>Summary</Headline>
          <SectionBody>
            <span>{userDescription}</span>
          </SectionBody>
        </Section>
        <Section>
          <Headline>Top skills</Headline>
          <SectionBody>
            <Utility fontSize='bigger'>
              {
                skills.sort((c, s) => s.rating - c.rating).map((skill) => <Badge key={skill.name}
                                                                                 text={skill.name} />)
              }
            </Utility>
            <Headline>Langauges</Headline>
            {
              languages.map((language) => <Language key={language.name} name={language.name}
                                                    level={language.level} />)
            }
          </SectionBody>
        </Section>
        <Section>
          <Headline>Teaching</Headline>
          <SectionBody>
            {teachings.map((teaching) =>
              <DetailsSection
                key={teaching.name}
                title={teaching.name}
                startDate={teaching.startDate}
                location={teaching.location}
                overview={teaching.overview}
              />
            )}
          </SectionBody>
        </Section>
        <Section>
          <Headline>Volunteering</Headline>
          <SectionBody>
            {volunteering.map((entry) =>
              <DetailsSection
                key={entry.name}
                title={entry.name}
                startDate={entry.startDate}
                overview={entry.overview}
              />
            )}
          </SectionBody>
        </Section>
        <Section>
          <Headline>Personal Projects</Headline>
          <SectionBody>
            {
              personalProjects.map((detail) =>
                <DetailsSection
                  key={detail.position + detail.company}
                  title={detail.name}
                  startDate={detail.startDate}
                  endDate={detail.endDate}
                  current={detail.current}
                  overview={detail.overview}
                  location={detail.location}
                  organisation={detail.company}
                  projects={detail.projects}
                  technologies={detail.technologies}
                />
              )
            }
          </SectionBody>
        </Section>
        <Section>
          <Headline>Find me online</Headline>
          <SectionBody>
            {userLinks.map((entry) =>
              <ContactEntry
                key={entry.label}
                label={entry.label}
                url={entry.url}
                text={entry.shortUrl}
                iconClassName={entry.iconClassName}
              />
            )}
          </SectionBody>
        </Section>
        <Section>
          <Headline>Passions</Headline>
          <SectionBody>
            {passions.map(passion => (
              <Passion key={passion.name} name={passion.name} iconClassName={passion.iconClassName} />
            ))}
          </SectionBody>
        </Section>
      </div>
    </div>
  </Page>
